<template>
  <div class="container-fluid">
    <organization-groups-form
      :loading="loading"
      :organizationGroupsData="organizationGroups"
      :formErrors="formErrors"
      @organizationGroupsSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultOrganizationGroups from "../defaultOrganizationGroups";
import OrganizationGroupsForm from "../partials/OrganizationGroupsForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    OrganizationGroupsForm,
  },

  mixins: [alertLeave],

  data() {
    const organizationGroups = cloneDeep(defaultOrganizationGroups);
    organizationGroups.taxes = cloneDeep(defaultTaxes);

    return {
      organizationGroups: organizationGroups,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(organizationGroups) {
      this.loading = true;

      const organizationGroupsData = cloneDeep(organizationGroups);
      delete organizationGroupsData.id;
       console.log(organizationGroupsData,"before send ");
      try {
        await this.$store.dispatch("organizationGroups/add", organizationGroupsData);
        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.ORGANIZATION_ADDED"),
        });
        const organizationGroups = await this.$store.getters[
          "organizationGroups/organizationGroups"
        ];
        this.$emit("onVieworganizationGroup", organizationGroups, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
