<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
<!--     <div class="form-wrapper full">
      <image-selector
        :defaultImage="organizationGroups?.logo"
        ressource_name="organizationGroups"
        :ressource_id="organizationGroups?.id || 0"
        field="logo"
        @imageChanged="
          (file_url) => {
            organizationGroups.logo = file_url;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.attachment" />
    </div> -->
<!-- Form for the Group ADd organization  -->
<!--     <div class="form-wrapper" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)">
      <base-input
        :label="`${$t('COMMON.RESELLER')} (*)`"
        :placeholder="$t('COMMON.RESELLER')"
      >
        <reseller-selector
          :reseller="organizationGroups?.reseller?.id"
          :filterable="true"
          :showAll="false"
          @resellerChanged="
            (resellerId) => {
              organizationGroups.reseller.id = resellerId;
              organizationGroups.owner.id = null;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.reseller" />
    </div> -->

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="organizationGroups.name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper full">
      <base-input
        type="textarea"
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor v-model="organizationGroups.description" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>
    <div class="form-wrapper full">
      <el-table-column
        :label="$t('COMMON.ORGANIZATIONS')"
        min-width="150px"
        prop="organizations"
        sortable="custom"
      >
        <template slot-scope="scope">
          <el-select
            v-model="selectedOrganizations[scope.row.id]"
            multiple
            collapse-tags
            placeholder="Select organizations"
          >
            <el-option
              v-for="org in organizations"
              :key="org.id"
              :label="org.name"
              :value="org.id"
            ></el-option>
          </el-select>

        </template>
      </el-table-column>
    </div>
<!-- 
    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.EMAIL')} (*)`"
        :placeholder="$t('COMMON.EMAIL')"
        v-model="organizationGroups.email"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.email" />
    </div> -->

    <!-- Other phone numbers -->
<!--     <div class="form-wrapper full">
      <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
        <phone-numbers-selector
          :phoneNumbers="organizationGroups?.other_phones"
          @phoneNumbersChanged="
            (phoneNumbers) => {
              organizationGroups.other_phones = phoneNumbers;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.other_phones" />
    </div> -->
<!-- 
    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.ADDRESS')} (*)`"
        :placeholder="$t('COMMON.ADDRESS')"
        v-model="organizationGroups.address"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.address" />
    </div> -->

<!--     <div class="form-wrapper taxes full">
      <taxes-selector
        :label="$t('COMMON.DEFAULT_TAXES')"
        :taxes="organizationGroups?.taxes"
        @taxesChanged="
          (taxes) => {
            organizationGroups.taxes = taxes;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.taxes" />
    </div> -->

<!--     <h2 class="col-12 mb-3">{{ $t("COMMON.BILLING_ADDRESS") }}</h2>

    <billing-informations-form
      :billingInformationsData="organizationGroups"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    /> -->
    <organization-selector
          v-if="
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) 
            /* && */
        /*     !filterObjectId */
          "
          @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
        />
   <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          organizationGroups?.id
            ? $t("ORGANIZATIONS.EDIT_ORGANIZATION_GROUP")
            : $t("ORGANIZATIONS.ADD_ORGANIZATION_GROUP")
        }}
      </base-button>
    </div>
  </form>
</template>

<script>
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { phoneTypesOptions } from "@/constants/common";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";

export default {
  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ResellerSelector,
    ImageSelector,
    TaxesSelector,
    BillingInformationsForm,
    [Select.name]: Select,
    [Option.name]: Option,
    PhoneNumberInput,
    PhoneNumbersSelector,
    OrganizationSelector,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [formMixin],

  props: {
    organizationGroupsData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    formErrors: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      organizationGroups: { ...this.organizationGroupsData },
      organizations: [],
      selectedOrganizations: {},
      selectedOrganization : 0 ,
      permissions: [],
      models: models,
      phoneTypesOptions,
    };
  },

  methods: {
    async handleSubmit() {
      let organizationGroupsData = cloneDeep(this.organizationGroups);
      if (organizationGroupsData.owner) {
        if (organizationGroupsData.owner.id == null) {
          organizationGroupsData.owner = null;
        }
      }
      organizationGroupsData = this.$fillUserResellerData(organizationGroupsData);
      this.$emit("organizationGroupsSubmitted", organizationGroupsData);
    },

    billingInformationsChanged(billingInformations) {
      this.organizationGroups = { ...this.organizationGroups, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    organizationGroupsData(organizationGroupsData) {
      if (organizationGroupsData) {
        this.organizationGroups = {
          ...this.organizationGroups,
          ...cloneDeep(organizationGroupsData),
        };
      }
    },
  },
};
</script>
