<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ organizationGroups.name }} - {{ $t("COMMON.ROLES") }}</h3>
    <role-list-table :filterOrganization="organization.id" />
  </div>
</template>

<script>
import RoleListTable from "../../RoleManagement/partials/RoleListTable.vue";

export default {
  name: "organization-group-view-roles",

  components: { RoleListTable },

  props: ["organizationGroups"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organizationGroups(organizationGroups) {},
  },
};
</script>
